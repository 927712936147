//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import IconError from '~/assets/img/icons/error-page.svg?inline';
import IconArrowOut from '~/assets/img/icons/arrow-out.svg?inline';
import {mapState} from 'vuex';

export default {
  name: 'NuxtError',
  props: {
    error: {
      type: Object,
      default: null
    }
  },
  async asyncData({error, store}) {
    const path = '/error';

    try {
      const story = await store.dispatch('page/getPageStory', {
        path
      });
      return {
        story
      }
    } catch (err) {
      console.error(err);
      error(err);
    }
  },
  components: {
    IconError,
    IconArrowOut
  },
  computed: {
    ...mapState('layout', {
      global: state => state.story?.content,
    }),
    status() {
      return this.error.status || 500;
    },
    statusText() {
      return this.global?.error_page_title || this.error.response || 'Something went wrong';
    },
    description() {
      return this.global?.error_page_description
    },
    buttonTitle() {
      return this.global?.error_home_page_link_title
    },
    metaTitle() {
      return `${this.status} | ${this.global?.meta_sitename}`;
    },
    linkHome() {
      return {
        linktype: 'story',
        cached_url: this.localePath('/home'),
      }
    },
  },
  head() {
    let head = {
      meta: [],
      link: [],
    };

    if (this.metaTitle) {
      head.title = this.metaTitle;
      head.meta.push(
        {hid: 'og:title', property: 'og:title', content: this.metaTitle},
        {hid: 'twitter:title', property: 'twitter:title', content: this.metaTitle},
      );
    }

    return head;
  }
}
