//
//
//
//
//
//

import IconArrow from '~/assets/img/icons/arrowToTop.svg?inline';

export default {
  name: "UiButtonToTop",
  components: {
    IconArrow,
  },
  methods: {
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    }
  }
}
