import { useStoryblokApi } from "@storyblok/nuxt-2";

export const state = () => ({
  events: null,
  categories: null,
  catalogItems: [],
  catalogItemsTotal: 0,
});

export const mutations = {
  setEvents(state, data) {
    state.events = data;
  },
  setCategories(state, data) {
    state.categories = data;
  },
  setEventsItems(state, data) {
    state.catalogItems = data;
  },
  updateEventsItems(state, data) {
    state.catalogItems = state.catalogItems.concat(data);
  },
  setEventsData(state, data) {
    state.catalogItemsTotal = data.total;
  },
};

export const actions = {
  async getEvent({ rootState, commit }, { filter_query, page, per_page }) {
    const starts_with = 'page-event/';
    const { version, language } = rootState;

    try {
      const resolve_relations = ['state'];
      const storyblokApi = useStoryblokApi();
      const result = await storyblokApi.get('cdn/stories', {
        version,
        language,
        starts_with,
        filter_query,
        page: page || 1,
        per_page: per_page || 9,
        is_startpage: false,
        sort_by: 'content.date:desc',
        content_type: 'page-event',
        excluding_fields: 'body,richtext,categories,meta_image,meta_title,meta_keywords,meta_description',
        resolve_relations: resolve_relations.join(),
      });

      const { stories, rels } = result.data;

      stories.forEach(story => {
        if (resolve_relations?.length && rels?.length) {
          resolve_relations.forEach(r => {
            if (story.content[r]?.length) {
              if (typeof story.content[r] === 'string') {
                // Resolve Single-Option
                story.content[r] = rels.find((i) => i.uuid === story.content[r]);
              } else {
                // Resolve Multi-Options
                story.content[r] = story.content[r].reduce((list, uuid) => {
                  const rel = rels.find((item) => item.uuid === uuid);
                  if (!rel) return list;

                  list.push(rel);
                  return list;
                }, []);
              }
            }
          });
        }
      });

      commit('setEvents', result);

      return result;
    } catch(err) {
      throw err;
    }
  },
  async getCategories({ rootState, commit }) {
    const starts_with = 'categories/';
    const { version, language } = rootState;

    try {
      const storyblokApi = useStoryblokApi();
      const { data } = await storyblokApi.get('cdn/stories', {
        version,
        language,
        starts_with,
        is_startpage: false,
        sort_by: 'position:asc',
        content_type: 'option-category',
      });
      const { stories } = data;

      commit('setCategories', stories);

      return stories;
    } catch(err) {
      throw err;
    }
  },
  async getEventsItems({ rootState, commit }, { filter_query, page, per_page, updateList, sort_by }) {
    const starts_with = 'programmes-and-projects/';
    const { version, language } = rootState;

    try {
      const resolve_relations = ['state'];
      const storyblokApi = useStoryblokApi();
      const result = await storyblokApi.get('cdn/stories', {
        version,
        language,
        starts_with,
        filter_query,
        page: page || 1,
        per_page: per_page || 25,
        is_startpage: false,
        sort_by: sort_by || 'updated_at:desc',
        content_type: 'page-event',
        excluding_fields: 'body,richtext,categories,meta_image,meta_title,meta_keywords,meta_description',
        resolve_relations: resolve_relations.join(),
      });

      if(updateList) {
        commit('updateEventsItems', result?.data?.stories);
      } else {
        commit('setEventsItems', result?.data?.stories);
      }
      commit('setEventsData', result);

      return result;
    } catch(err) {
      throw err;
    }
  },

};
