import { useStoryblokApi } from "@storyblok/nuxt-2";
import { convertLiterals } from '~/utils/helpers';

export const state = () => ({
  story: null,
  categories: null,
  uaInstituteLogo: null,
  topics: null,
  literals: null,
  windowWidth: 1920,
});

export const getters = {
  isMobile(state) {
    return state.windowWidth < 767
  },
  isTablet(state) {
    return state.windowWidth < 1440 && state.windowWidth > 767
  },
  isTabletSm(state) {
    return state.windowWidth < 1024 && state.windowWidth > 767
  },
  isDesktop(state) {
    return state.windowWidth >= 1440
  }
}

export const mutations = {
  setLayoutStory(state, story) {
    state.story = story;
    state.literals = convertLiterals(story.content.literals);
  },
  setCategories(state, data) {
    state.categories = data;
  },
  setTopics(state, data) {
    state.topics = data;
  },
  setWindowWidth(state, data) {
    state.windowWidth = data;
  },
};

export const actions = {
  async getLayoutStory({ rootState, commit }) {
    const path = '/layout-config';
    const { version, language } = rootState;

    try {
      const storyblokApi = useStoryblokApi();
      const { data } = await storyblokApi.get(`cdn/stories${path}`, {
        version,
        language,
      });
      const { story } = data;

      commit('setLayoutStory', story);

      return story;
    } catch(err) {
      throw err;
    }
  },
  async getCategories({ rootState, commit }) {
    const starts_with = 'categories/';
    const { version, language } = rootState;

    try {
      const storyblokApi = useStoryblokApi();
      const { data } = await storyblokApi.get('cdn/stories', {
        version,
        language,
        starts_with,
        is_startpage: false,
        sort_by: 'position:asc',
        content_type: 'option-category',
      });
      const { stories } = data;

      commit('setCategories', stories);

      return stories;
    } catch(err) {
      throw err;
    }
  },
  async getTopics({ rootState, commit }) {
    const starts_with = 'topics/';
    const { version, language } = rootState;

    try {
      const storyblokApi = useStoryblokApi();
      const { data } = await storyblokApi.get('cdn/stories', {
        version,
        language,
        starts_with,
        is_startpage: false,
        sort_by: 'position:asc',
        content_type: 'option-topic',
      });
      const { stories } = data;

      commit('setTopics', stories);

      return stories;
    } catch(err) {
      throw err;
    }
  },
};
