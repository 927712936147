import { useStoryblokApi } from "@storyblok/nuxt-2";

export const state = () => ({
  story: null,
  links: null
});

export const mutations = {
  setPageStory(state, story) {
    state.story = story;
  },
  setLinks(state, links) {
    state.links = Object.values(links);
  },
};

export const actions = {
  async getPageStory({ rootState, commit }, { path }) {
    const { version, language } = rootState;

    try {
      const storyblokApi = useStoryblokApi();
      const { data } = await storyblokApi.get(`cdn/stories${path}`, {
        version,
        language,
      });
      const { story } = data;
      commit('setPageStory', story);

      return story;
    } catch(err) {
      throw err;
    }
  },
  async getLinks({ commit }) {
    try {
      const storyblokApi = useStoryblokApi();
      const { data } = await storyblokApi.get(`cdn/links/`);
      const { links } = data;
      commit('setLinks', links);

      return links;
    } catch(err) {
      throw err;
    }
  },
};
