// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../fonts/UkrainianInstituteRegular.woff2");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../fonts/UkrainianInstituteLight.woff2");
var ___CSS_LOADER_URL_IMPORT_2___ = require("../fonts/UkrainianInstituteBold.woff2");
var ___CSS_LOADER_URL_IMPORT_3___ = require("../fonts/UkrainianInstituteItalic.woff2");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face{font-display:swap;font-family:\"UkrainianInstitute\";font-style:normal;font-weight:400;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"woff2\")}@font-face{font-display:swap;font-family:\"UkrainianInstituteLight\";font-style:normal;font-weight:300;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format(\"woff2\")}@font-face{font-display:swap;font-family:\"UkrainianInstituteBold\";font-style:normal;font-weight:800;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format(\"woff2\")}@font-face{font-display:swap;font-family:\"UkrainianInstituteItalic\";font-style:italic;font-weight:400;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ") format(\"woff2\")}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;
