//
//
//
//
//
//
//
//

export default {
  name: "UiNavButton",
  props: {
    active: {
      type: Boolean,
      default: false
    },
    isDarkButton: {
      type: Boolean,
      default: false
    }
  }
}
