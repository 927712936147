//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapGetters, mapState} from "vuex";
import IconSearch from '~/assets/img/icons/fi_search.svg?inline';
import IconClose from '~/assets/img/icons/close.svg?inline';

export default {
  name: 'UiSearch',
  props: {
    blok: {
      type: Object,
      required: true,
    },
    showSearchFlag: {
      type: Boolean,
      required: false,
    },
  },
  components: {
    IconSearch,
    IconClose,
  },
  watch: {
    '$route.query': {
      handler: function () {
        this.$nextTick(() => {
          this.updateData()
        })
      },
    }
  },
  mounted() {
    this.updateData()
    if(this.isTablet) {
      document.getElementById("search").addEventListener("focusout", this.goSearch);
    }
  },
  destroyed() {
    document.getElementById("search").removeEventListener("focusout", this.goSearch);
  },
  data() {
    return {
      search: '',
      isSearchActive: false,
      isCatalogPage: false,
    }
  },
  computed: {
    ...mapState('layout', ['literals','topics',]),
    ...mapGetters('layout', ['isTablet']),
    show: {
      get() {
        return this.showSearchFlag
      },
      set(value) {
        this.$emit('update:showSearchFlag', value)
      }
    },
  },
  methods: {
    updateData() {
      const {query, path} = this.$route;
      const search = query?.search ?? ''

      this.search = search
      this.isSearchActive = !!this.search

      this.isCatalogPage = path === "/programmes-and-projects"

      if (this.isCatalogPage) {
        this.show = !!this.search
      } else {
        this.show = false
      }
    },
    updateSearch(event) {
      if (event.key === "Enter" || event.which === 13) {
        event.preventDefault();
        this.goSearch()
      }
    },
    goSearch() {
      let path = {
        path: '/programmes-and-projects',
        query: {
          search: this.search,
          topic: this.$route.query?.topic,
        },
        hash: this.$route?.hash ?? undefined,
      }

      const {hash} = this.$route;
      if (hash) {
        path = {
          ...path,
          hash: hash
        }
      }
      this.$router.push(path)
    },
    focusSearch() {
      this.$refs.input.focus()
    },
    clearSearch() {
      this.search = ''
      this.$nextTick(() => {
        this.goSearch()
      })
    }
  }
};
