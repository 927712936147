//
//
//
//
//
//


export default {
  name: 'BaseButton',
  props: {
    type: {
      type: String,
      default: 'default',
    },
    disabled: {
      type: Boolean,
      default: false,
    }
  },
  computed: {},
  methods: {},
};
