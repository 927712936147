import { addEndTrailingSlash, removeEndTrailingSlash } from '~/utils/helpers';

export default ({ route, redirect, store, i18n }) => {
  const { path, query, hash } = route;
  const { localeCodes, defaultLocale } = i18n;
  const prefixedLocales = localeCodes.filter(i => i !== defaultLocale);
  const pathWithSlash = addEndTrailingSlash(path);
  const pathWithoutSlash = removeEndTrailingSlash(path);

  // ADD END TRAILING SLASH TO I18N PAGES
  if (prefixedLocales.some(i => path === `/${i}`)) {
    return redirect(302, {
      path: pathWithSlash,
      query,
      hash
    });
  }

  // REDIRECT TO INDEX PAGE
  if (pathWithoutSlash === '/home') {
    return redirect(302, {
      path: '/',
      query,
      hash
    });
  }
  // if (pathWithoutSlash === '/fr/home') {
  //   return redirect(302, {
  //     path: '/fr/',
  //     query,
  //     hash
  //   });
  // }
};
