//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapState, mapActions, mapMutations} from 'vuex';
import eventBus from '~/plugins/eventBus'

export default {
  name: 'LayoutsDefault',
  computed: {
    ...mapState('layout', ['story']),
    content() {
      return this.story?.content;
    },
    header() {
      return this.content?.header || [];
    },
    footer() {
      return this.content?.footer || [];
    },
  },
  async fetch() {
    try {
      await Promise.all([
        this.getLayoutStory(),
        this.getCategories(),
        this.getTopics(),
      ])
    } catch (err) {
      console.error({err});
    }
  },
  data() {
    return {
      headerTheme: 'dark',
      showButtonFlag: false
    }
  },
  created() {
    eventBus.$on('updateHeaderTheme', value => {
      this.headerTheme = value ? 'dark' : 'light'
    })
    if (process.client) {
      window.addEventListener('resize', this.checkSize)
      window.addEventListener('scroll', this.onScroll)
    }
  },
  mounted() {
    this.checkSize()
  },
  destroyed() {
    window.removeEventListener('resize', this.checkSize)
    window.removeEventListener('scroll', this.onScroll)
  },
  methods: {
    ...mapActions('layout', ['getLayoutStory', 'getCategories', 'getTopics',]),
    ...mapMutations('layout', ['setWindowWidth']),
    checkSize() {
      this.setWindowWidth(window.innerWidth)
    },
    onScroll() {
      this.showButtonFlag = window.top.scrollY >= window.innerHeight
    }
  },
  head() {
    const i18nHead = this.$nuxtI18nHead({addSeoAttributes: true});

    return {
      htmlAttrs: {
        ...i18nHead.htmlAttrs,
      },
      bodyAttrs: {
        'data-aos-easing': 'ease-out',
        'data-aos-duration': 400,
        'data-aos-delay': 0,
      },
      meta: [
        ...i18nHead.meta,
      ],
      link: [
        ...i18nHead.link,
      ],
    };
  },
};
