//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import IconClose from "~/assets/img/icons/close.svg?inline";
import IconArrow from '~/assets/img/icons/arrow.svg?inline';
import {mapState} from "vuex";

export default {
  name: "UiMobileNav",
  props: {
    blok: {
      type: Object,
      required: true,
    },
    allTopics: {
      type: Array,
      default: () => []
    },
    logo: {
      type: Object,
      default: () => {
      }
    },
    footerBlok: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    setTimeout(() => {this.navButtonFlag = true}, 0)
  },
  beforeDestroy() {
    this.navButtonFlag = false
  },
  data() {
    return {
      showDropdownFlag: false,
      navButtonFlag: false,
    }
  },
  components: {
    IconClose,
    IconArrow
  },
  computed: {
    ...mapState('layout', ['literals']),
    linkHome() {
      return {
        linktype: 'story',
        cached_url: this.localePath('/home'),
      }
    },
    navigationList() {
      const data = [...this.blok?.navigation]
      return data.reverse()
    },
    isCatalogPage() {
      const {path} = this.$route;
      return path === "/programmes-and-projects"
    }
  },
  methods: {
    handleDropdownEvent() {
      this.showDropdownFlag = !this.showDropdownFlag
    },
    goTo(slug) {
      this.showDropdownFlag = false
      this.$router.push({
        path: 'programmes-and-projects',
        query: {
          topic: slug
        },
        hash: this.$route?.hash ?? undefined,
      })
      this.$emit('close');
    }
  }
}
