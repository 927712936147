//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapGetters, mapState} from "vuex";
import IconArrow from '~/assets/img/icons/arrow.svg?inline';
import IconSearch from '~/assets/img/icons/fi_search.svg?inline';

export default {
  name: 'LayoutHeader',
  props: {
    blok: {
      type: Object,
      required: true,
    },
    footerBlok: {
      type: Object,
      required: true,
    },
    theme: {
      type: String,
      required: 'light',
    },
  },
  components: {
    IconArrow,
    IconSearch,
  },
  data() {
    return {
      showDropdownFlag: false,
      showSearchFlag: false,
      showNav: false,
      search: ''
    }
  },
  computed: {
    ...mapState('layout', ['topics', 'windowWidth']),
    ...mapGetters('layout', ['isMobile']),
    isDarkMode() {
      return this.themeData === 'light'
    },
    logo() {
      return this.isDarkMode ? this.blok?.logo_dark : this.blok?.logo
    },
    linkHome() {
      return {
        linktype: 'story',
        cached_url: this.localePath('/home'),
      }
    },
    navigation() {
      return this.blok?.navigation.filter(i => !i.only_for_mobile) ?? []
    },
    themeData() {
      return this.showSearchFlag ? 'light' : this.theme
    },
    isCatalogPage() {
      const {path} = this.$route;
      return path === "/programmes-and-projects"
    }
  },
  methods: {
    handleDropdownEvent() {
      this.showDropdownFlag = !this.showDropdownFlag
    },
    openDropdown() {
      if (this.windowWidth > 1025) {
        this.showDropdownFlag = true
      }
    },
    hideDropdown() {
      if (this.windowWidth > 1025) {
        this.showDropdownFlag = false
      }
    },
    showSearch() {
      this.showSearchFlag = !this.showSearchFlag
      this.$nextTick(() => {
        if (this.showSearchFlag) {
          this.$refs.search.focusSearch()
        }
      })
    },
    hideSearch() {
      this.showSearchFlag = false;
      this.search = ''
    },
    goTo(slug) {
      this.showDropdownFlag = false
      this.$router.push({
        path: '/programmes-and-projects',
        query: {
          topic: slug
        },
        hash: this.$route?.hash ?? undefined,
      })
    }
  }
};
