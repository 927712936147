//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {getStoryLink, isSbInternalLinkFilled} from '~/utils/helpers';

export default {
  name: 'BaseLinkWr',
  props: {
    link: {
      type: [Object, String],
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isStoryLinkFilled() {
      return isSbInternalLinkFilled(this.link)
    },
    getPath() {
      const link = this.link;
      const linktype = link?.linktype;
      const path = getStoryLink(link);

      if (linktype === 'story' && !this.isStoryLinkFilled) return null;

      return path;
    },
    getLinktype() {
      const linktype = this.link?.linktype;

      if (linktype === 'story' && !this.isStoryLinkFilled) return null;

      return linktype;
    },
    getTarget() {
      return this.link?.target || null;
    },
    getRel() {
      const res = []; // ['nofollow'];
      const sbRel = this.link?.rel;

      if (this.getTarget) res.push('noopener noreferrer');
      if (sbRel) res.push(sbRel);

      return res.join(' ');
    },
    getTitle() {
      const sbTitle = this.link?.title;

      return sbTitle || null;
    },
    getAnchor() {
      return this.link?.anchor;
    },
    getHash() {
      const anchor = this.getAnchor;

      return anchor ? `#${anchor}` : '';
    },
    isExact() {
      return this.getPath === '/'
    },
  },
  methods: {
    handleClickNuxt(e, navigate) {
      const anchor = this.getAnchor;
      const anchorEl = document.getElementById(anchor);

      if (anchor && anchorEl) {
        e.preventDefault();

        const header = document.getElementById('header');
        const offset = header ? -header.offsetHeight : 0;
        const duration = 500;
        const options = {
          offset,
          lazy: false,
        };

        this.$scrollTo(anchorEl, duration, options);
      } else {
        navigate(e);
      }

      this.$emit('click', e);
    },
    handleClick(e) {
      this.$emit('click', e);
    },
  },
};
