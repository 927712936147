export const LayoutConfig = () => import('../../components/storyblok/layout/Config.vue' /* webpackChunkName: "components/layout-config" */).then(c => wrapFunctional(c.default || c))
export const LayoutFooter = () => import('../../components/storyblok/layout/Footer.vue' /* webpackChunkName: "components/layout-footer" */).then(c => wrapFunctional(c.default || c))
export const LayoutHeader = () => import('../../components/storyblok/layout/Header.vue' /* webpackChunkName: "components/layout-header" */).then(c => wrapFunctional(c.default || c))
export const PageAboutUs = () => import('../../components/storyblok/page/AboutUs.vue' /* webpackChunkName: "components/page-about-us" */).then(c => wrapFunctional(c.default || c))
export const PageCatalogOverview = () => import('../../components/storyblok/page/CatalogOverview.vue' /* webpackChunkName: "components/page-catalog-overview" */).then(c => wrapFunctional(c.default || c))
export const PageConstructor = () => import('../../components/storyblok/page/Constructor.vue' /* webpackChunkName: "components/page-constructor" */).then(c => wrapFunctional(c.default || c))
export const PageEvent = () => import('../../components/storyblok/page/Event.vue' /* webpackChunkName: "components/page-event" */).then(c => wrapFunctional(c.default || c))
export const PageProject = () => import('../../components/storyblok/page/Project.vue' /* webpackChunkName: "components/page-project" */).then(c => wrapFunctional(c.default || c))
export const BlokBlocksArtCurators = () => import('../../components/storyblok/blok/blocks/ArtCurators.vue' /* webpackChunkName: "components/blok-blocks-art-curators" */).then(c => wrapFunctional(c.default || c))
export const BlokBlocksArticles = () => import('../../components/storyblok/blok/blocks/Articles.vue' /* webpackChunkName: "components/blok-blocks-articles" */).then(c => wrapFunctional(c.default || c))
export const BlokBlocksBooks = () => import('../../components/storyblok/blok/blocks/Books.vue' /* webpackChunkName: "components/blok-blocks-books" */).then(c => wrapFunctional(c.default || c))
export const BlokBlocksFilms = () => import('../../components/storyblok/blok/blocks/Films.vue' /* webpackChunkName: "components/blok-blocks-films" */).then(c => wrapFunctional(c.default || c))
export const BlokBlocksGallery = () => import('../../components/storyblok/blok/blocks/Gallery.vue' /* webpackChunkName: "components/blok-blocks-gallery" */).then(c => wrapFunctional(c.default || c))
export const BlokBlocksImage = () => import('../../components/storyblok/blok/blocks/Image.vue' /* webpackChunkName: "components/blok-blocks-image" */).then(c => wrapFunctional(c.default || c))
export const BlokBlocksPartners = () => import('../../components/storyblok/blok/blocks/Partners.vue' /* webpackChunkName: "components/blok-blocks-partners" */).then(c => wrapFunctional(c.default || c))
export const BlokBlocksPeople = () => import('../../components/storyblok/blok/blocks/People.vue' /* webpackChunkName: "components/blok-blocks-people" */).then(c => wrapFunctional(c.default || c))
export const BlokBlocksQuote = () => import('../../components/storyblok/blok/blocks/Quote.vue' /* webpackChunkName: "components/blok-blocks-quote" */).then(c => wrapFunctional(c.default || c))
export const BlokBlocksSpotify = () => import('../../components/storyblok/blok/blocks/Spotify.vue' /* webpackChunkName: "components/blok-blocks-spotify" */).then(c => wrapFunctional(c.default || c))
export const BlokBlocksText = () => import('../../components/storyblok/blok/blocks/Text.vue' /* webpackChunkName: "components/blok-blocks-text" */).then(c => wrapFunctional(c.default || c))
export const BlokBlocksVimeoVideo = () => import('../../components/storyblok/blok/blocks/VimeoVideo.vue' /* webpackChunkName: "components/blok-blocks-vimeo-video" */).then(c => wrapFunctional(c.default || c))
export const BlokBlocksYoutubeVideo = () => import('../../components/storyblok/blok/blocks/YoutubeVideo.vue' /* webpackChunkName: "components/blok-blocks-youtube-video" */).then(c => wrapFunctional(c.default || c))
export const BaseButton = () => import('../../components/base/Button.vue' /* webpackChunkName: "components/base-button" */).then(c => wrapFunctional(c.default || c))
export const BaseDropdown = () => import('../../components/base/Dropdown.vue' /* webpackChunkName: "components/base-dropdown" */).then(c => wrapFunctional(c.default || c))
export const BaseLinkWr = () => import('../../components/base/LinkWr.vue' /* webpackChunkName: "components/base-link-wr" */).then(c => wrapFunctional(c.default || c))
export const BaseModal = () => import('../../components/base/Modal.vue' /* webpackChunkName: "components/base-modal" */).then(c => wrapFunctional(c.default || c))
export const BasePreloader = () => import('../../components/base/Preloader.vue' /* webpackChunkName: "components/base-preloader" */).then(c => wrapFunctional(c.default || c))
export const BaseRichtext = () => import('../../components/base/Richtext.vue' /* webpackChunkName: "components/base-richtext" */).then(c => wrapFunctional(c.default || c))
export const UiButtonToTop = () => import('../../components/ui/ButtonToTop.vue' /* webpackChunkName: "components/ui-button-to-top" */).then(c => wrapFunctional(c.default || c))
export const UiGalleryItem = () => import('../../components/ui/GalleryItem.vue' /* webpackChunkName: "components/ui-gallery-item" */).then(c => wrapFunctional(c.default || c))
export const UiMobileNav = () => import('../../components/ui/MobileNav.vue' /* webpackChunkName: "components/ui-mobile-nav" */).then(c => wrapFunctional(c.default || c))
export const UiNavButton = () => import('../../components/ui/NavButton.vue' /* webpackChunkName: "components/ui-nav-button" */).then(c => wrapFunctional(c.default || c))
export const UiProjectsTable = () => import('../../components/ui/ProjectsTable.vue' /* webpackChunkName: "components/ui-projects-table" */).then(c => wrapFunctional(c.default || c))
export const UiSearch = () => import('../../components/ui/Search.vue' /* webpackChunkName: "components/ui-search" */).then(c => wrapFunctional(c.default || c))
export const UiWaterfall = () => import('../../components/ui/Waterfall.vue' /* webpackChunkName: "components/ui-waterfall" */).then(c => wrapFunctional(c.default || c))
export const RichtextBlocksImage = () => import('../../components/richtext/blocks/Image.vue' /* webpackChunkName: "components/richtext-blocks-image" */).then(c => wrapFunctional(c.default || c))
export const RichtextMarksLink = () => import('../../components/richtext/marks/Link.vue' /* webpackChunkName: "components/richtext-marks-link" */).then(c => wrapFunctional(c.default || c))
export const UiEventPreview = () => import('../../components/ui/event/EventPreview.vue' /* webpackChunkName: "components/ui-event-preview" */).then(c => wrapFunctional(c.default || c))
export const UiEventPreviewType = () => import('../../components/ui/event/EventPreviewType.vue' /* webpackChunkName: "components/ui-event-preview-type" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
