//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapState} from "vuex";

export default {
  name: 'LayoutFooter',
  props: {
    blok: {
      type: Object,
      required: true,
      default: () => {
      }
    },
  },
  computed: {
    ...mapState('layout', ['topics', 'windowWidth']),
    logo() {
      return this.blok?.logo
    },
    linkHome() {
      return {
        linktype: 'story',
        cached_url: this.localePath('/home'),
      }
    },
    copyright() {
      const text = this.blok.copyright;
      const regex = /\$\{year\}/gm;
      const str = text ? text : '';
      const subst = new Date().getFullYear();

      return str.replace(regex, subst);
    },
  },
  methods: {
    getUrl(link) {
      return link?.url ?? ''
    },
    goTo(slug) {
      this.showDropdownFlag = false
      this.$router.push({
        path: '/programmes-and-projects',
        query: {
          topic: slug
        },
        hash: this.$route?.hash ?? undefined,
      })
    }
  }
}
